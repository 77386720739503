<template>
  <div class="border-b-4 border-l border-t border-r border-white border-solid max-w-lg mt-4 shadow-md w-full flex flex-col shadow-glowWhite">
    <figure class="w-full block relative">
      <img class="block w-full h-48 object-cover object-center" :src="`${$store.getters.URLSITE}/photos/battles/${getBattle.battleSettings.image}_m.jpg`" :alt="getBattle.battleSettings.name">
      <figcaption class="absolute flex font-bold inset-0 items-center justify-center p-4 py-2 text-3xl text-center text-shadow1px1">{{ getBattle.battleSettings.name }}</figcaption>
    </figure>
    <div class="flex items-stretch justify-center relative select-none opacity-50">
      <div class="flex-grow mr-2 pl-1 pr-0 py-2 text-shadow1px bg-green-our boxTimeRemainClipStart bg-opacity-30">UPLOAD</div>
      <div class="-ml-3 flex-grow leading-6 mr-2 pl-3 pr-0 py-2 boxTimeRemainBlue bg-blue-our boxTimeRemainClipMiddle bg-opacity-30">VOTE</div>
      <div class="-ml-3 flex-grow leading-6 pr-4 py-2 pl-3 boxTimeRemainPink boxTimeRemainClipEnd bg-pink-700 bg-opacity-30">RESULTS</div>
    </div>
    <div class="bg-black bg-opacity-75 flex-grow">
      <div class="animationNeon1 font-bold py-28 text-4xl text-center text-white" v-if="zostavajuciCas.show">{{zostavajuciCas.time}}</div>
    </div>
    <div class="bg-opacity-60 bg-white flex justify-between p-2 h-7">
    </div>
  </div>
</template>

<script>
import functions from '../functions.vue';
export default {
    mixins: [functions],
    props: ["battleId"],
    computed:{
      getBattle(){
        return this.$store.getters.getBattle(this.battleId);
      },
      zostavajuciCas(){
        let cas=this.getTimeRemainingWithDays(this.getBattle.battleSettings.toBattleStart);
        if(cas.days){
          if(cas.days==="SOON"){
            return {show:true,time:`COMMING ${cas.days}`}
          }else{
            return {show:true,time:`STARTS IN ${cas.days}`}
          }
        }else if(cas.hours){
          return {show:true,time:`STARTS IN ${cas.hours}`}
        }
        return {show:false,time:""}
      },
    },
}
</script>
